<template>
<div>
  <b-navbar toggleable="lg" type="dark" variant="primary" class="custom-navigation">
    <b-container class="my-1">
      <b-navbar-brand class="d-flex align-items-center py-0" :to="{ name: 'HomepageView' }">
        <logo-component :fill="'#fff'" :height="'32px'" :width="'32px'" class="mr-3" :lineBreak="false">
          Adobe Cloud Store
        </logo-component>
      </b-navbar-brand>
      <b-navbar-toggle target="nav-collapse">
        <template #default="{ expanded }">
          <fa-icon v-if="expanded" :icon="['fa-regular', 'fa-bars']"></fa-icon>
          <fa-icon v-else :icon="['fa-regular', 'fa-bars']"></fa-icon>
        </template>
      </b-navbar-toggle>
      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav>
          <b-nav-item
            :active="
              $route.name === 'CreateOrderSelectCustomerView' ||
              $route.name === 'CreateOrderSelectProductsView' ||
              $route.name === 'CreateOrderCheckoutView'
            "
            :to="{ name: 'CreateOrderSelectCustomerView' }"
          >
            {{ $t("createOrder") }}
          </b-nav-item>
          <b-nav-item :active="$route.name === 'CustomersView'" :to="{ name: 'CustomersView' }">
            {{ $t("customers") }}
          </b-nav-item>

          <b-nav-item-dropdown>
            <template #button-content>{{ $t("transferCustomer") }}</template>

            <b-dropdown-item
              :active="$route.name === 'TransferSubscriptionsView' || $route.name === 'TransferSubscriptionsPrevieOffersView'"
              :to="{ name: 'TransferSubscriptionsView' }"
            >
              {{ $t("vipToVipmp") }}
            </b-dropdown-item>

            <b-dropdown-item
              :active="$route.name === 'ChangeOfResellerPreviewView'"
              :to="{ name: 'ChangeOfResellerPreviewView' }"
            >
            {{ $t("resellerToReseller") }}
            </b-dropdown-item>
          </b-nav-item-dropdown>
        </b-navbar-nav>
        <b-navbar-nav class="ml-auto">
          <b-nav-item-dropdown right>
            <template #button-content>
              <fa-icon :icon="['fa-regular', 'fa-bars']" class="mr-1"></fa-icon>
            </template>

            <b-dropdown-header>{{ $t("accountLabel") }}</b-dropdown-header>
            <b-dropdown-text style="width: 240px">
              {{ username }}
            </b-dropdown-text>
            <b-dropdown-text style="width: 240px">
              {{ resellerId }}
            </b-dropdown-text>
            <b-dropdown-text style="width: 240px">
              <div class="d-flex align-items-center">
                <strong class="mr-1">{{ $t("yourLimit") }}:</strong>
                <customer-limit-component />
              </div>
            </b-dropdown-text>

            <b-dropdown-divider></b-dropdown-divider>
            <b-dropdown-header>{{ $t("settings") }}</b-dropdown-header>
            <b-dropdown-item
              :active="$route.name === 'ResellerEditLoginCredentialsView'"
              :to="{ name: 'ResellerEditLoginCredentialsView' }"
            >
              <fa-icon :icon="['fa-regular', 'fa-user-unlock']" fixed-width class="mr-1"></fa-icon
              >{{ $t("editLoginCredentials") }}
            </b-dropdown-item>

            <b-dropdown-item
              :active="$route.name === 'ResellerProfileView'"
              :to="{ name: 'ResellerProfileView' }"
              v-show="$store.state.resellerData.isAdmin"
            >
              <fa-icon :icon="['fa-regular', 'fa-building']" fixed-width class="mr-1"></fa-icon>{{ $t("companyData") }}
            </b-dropdown-item>

            <b-dropdown-item
              :active="$route.name === 'ResellerContactsView'"
              :to="{ name: 'ResellerContactsView' }"
              v-show="$store.state.resellerData.isAdmin"
            >
              <fa-icon :icon="['fa-regular', 'fa-users']" fixed-width class="mr-1"></fa-icon>{{ $t("contacts") }}
            </b-dropdown-item>

            <b-dropdown-item
              :active="$route.name === 'ResellerNotificationsView'"
              :to="{ name: 'ResellerNotificationsView' }"
              v-show="$store.state.resellerData.isAdmin"
            >
              <fa-icon :icon="['fa-regular', 'fa-envelope']" fixed-width class="mr-1"></fa-icon
              >{{ $t("notifications") }}
            </b-dropdown-item>

            <b-dropdown-item
              :active="$route.name === 'ResellerUserManagementView'"
              :to="{ name: 'ResellerUserManagementView' }"
              v-show="$store.state.resellerData.isAdmin"
            >
              <fa-icon :icon="['fa-regular', 'fa-users']" fixed-width class="mr-1"></fa-icon>{{ $t("userManagement") }}
            </b-dropdown-item>

            <b-dropdown-divider></b-dropdown-divider>
            <b-dropdown-header>{{ $t("language") }}</b-dropdown-header>
            <b-dropdown-item v-on:click="switchLanguage('de')">
              <span class="fi fi-de"></span> Deutsch
            </b-dropdown-item>
            <b-dropdown-item v-on:click="switchLanguage('en')">
              <span class="fi fi-eu"></span> English (EU)
            </b-dropdown-item>

            <b-dropdown-divider></b-dropdown-divider>
            <b-dropdown-header>{{ $t("support") }}</b-dropdown-header>
            <b-dropdown-item :active="$route.name === 'FaqView'" :to="{ name: 'FaqView' }">
              <fa-icon :icon="['fa-regular', 'fa-circle-question']" fixed-width class="mr-1"></fa-icon>{{ $t("faq") }}
            </b-dropdown-item>

            <b-dropdown-divider></b-dropdown-divider>
            <b-dropdown-text style="width: 240px" class="py-2">
              <b-button block v-on:click="signOutUser" variant="outline-dark">
                <fa-icon :icon="['fa-regular', 'fa-arrow-right-from-bracket']" fixed-width class="mr-1"></fa-icon
                >{{ $t("signout") }}
              </b-button>
            </b-dropdown-text>
          </b-nav-item-dropdown>
        </b-navbar-nav>
      </b-collapse>
    </b-container>
  </b-navbar>
</div>
</template>

<script>
import LogoComponent from "../components/LogoComponent.vue";
import CustomerLimitComponent from "./CustomerLimitComponent.vue";
import { auth } from "../plugins/firebase";
import { signOut } from "firebase/auth";

export default {
  name: "NavigationComponent",
  components: {
    LogoComponent,
    CustomerLimitComponent,
  },
  computed: {
    username() {
      return this.$store.state.resellerData.displayName;
    },
    resellerId() {
      return this.$store.state.resellerData.resellerId;
    },
  },
  created() {
    if (this.$i18n.locale === "en") {
      this.selectedIcon = "eu";
    }
  },
  methods: {
    signOutUser: function () {
      this.$store.state.resellerDocListener();
      if (auth.currentUser.uid.includes("-")) {
        this.$store.state.subuserDocListener();
      }
      this.$store.state.notificationsDocListener();
      signOut(auth)
        .then(() => this.$router.replace("/"))
        .catch((error) => console.error(error.message));
    },
    switchLanguage(language) {
      window.localStorage.setItem("comline-acs-lang", language);
      this.$i18n.locale = language;
      this.selectedLangauge = language;
      if (language === "en") {
        this.selectedIcon = "eu";
      } else {
        this.selectedIcon = language;
      }
    },
  },
  data() {
    return {
      selectedLangauge: "de",
      selectedIcon: "de",
      display: true,
    };
  },
};
</script>

<style scoped>
.b-dropdown-text {
  font-weight: normal;
}
</style>
