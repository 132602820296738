import { firestore } from "../firebase";
import { collection, orderBy, query, getDocs } from "firebase/firestore";
import moment from "moment-timezone";
import PouchDB from 'pouchdb';

const db = new PouchDB('productData', {revs_limit: 2});

function isToday( date ){
  const timeNow = moment.tz("America/Los_Angeles")
  if(!date) return false;
  return moment(timeNow).isSame(date, 'day')
}


/**
 * Loads products from the Firebase Firestore database and saves them to a PouchDB instance.
 *
 * @async
 * @param {string} resellerId - The ID of the customer to load data for.
 * @param {object} [doc] - An optional object to save with the product data. If no _id property is present, it will be generated from the customer ID.
 * @returns {Promise<object[]>} A promise that resolves to an array of loaded products.
 */
async function loadDatafromServer (resellerId, doc){
  const products = []
  const productsColRef = query(collection(firestore, "products"), orderBy("productName"));
  const productsquery = await getDocs(productsColRef);
  productsquery.forEach(product=>products.push(product.data()));

  const dataToSave = doc || {}
  if (!dataToSave._id){
    dataToSave._id = String(resellerId)
  }
  dataToSave.checkDate = moment.tz("America/Los_Angeles").toString()
  dataToSave.data = JSON.stringify(products)
  
  await db.put(dataToSave)
  .then(() =>{console.log("saved", dataToSave)})
  .catch(function (err) {
    console.log(err);
  });

  return products
}


/**
 * Function to retrieve the prices of the products from the database or localStorage.
 * @param {String} resellerId - The input data for the request.
 * @returns {Promise} A promise resolving with the products and their corresponding prices.
 */
async function getProductsFromServer(resellerId ) {
  const dbData = await db.get(String(resellerId))
  .catch(()=>{return loadDatafromServer(resellerId)})

  // datum prüfen LA Zeit
  if (isToday(new Date(dbData.checkDate))) {
    return await JSON.parse(dbData.data)
  } else {
    return loadDatafromServer(resellerId, dbData)
  }
}

  
export { getProductsFromServer }