import Vue from "vue";
import Vuex from "vuex";
Vue.use(Vuex);

import i18n from "../i18n";
import { functions } from "../firebase"
import { httpsCallable } from "firebase/functions";
import settings from "../../settings.js"
import { version } from "./../../../package.json"

export default new Vuex.Store({
  state: {
    version: version.replaceAll(".", ""),
    newVersion: "0",
    notifications: [],
    resellerData: null,
    resellerSubUserData: null,
    alertError: {
      message: "",
      show: false,
      noFooter: false,
    },
    alertSuccess: {
      message: "",
      show: false,
      noFooter: false,
    },
    showNewsPanel: false,
    nameRegex: /^[a-zA-Z\x7f-\xff\s-ęóśłżźćń]{1,35}$/,
    companyNameRegex: /^[a-zA-Z0-9\x7f-\xff\s-&.ęóśłżźćń]{4,80}$/,
    postalCodeRegex: {
      AD: /^AD\d{3}$/,
      AT: /^\d{4}$/,
      BE: /^(B-)?\d{4}$/,
      BG: /^\d{4}$/,
      HR: /^\d{5}$/,
      CY: /^\d{4}$/,
      CZ: /^\d{3}([ ]?\d{2})$/,
      DK: /^\d{4}$/,
      EE: /^\d{5}$/,
      CH: /^\d{4}$/,
      DE: /^(?!01000|99999)(0[1-9]\d{3}|[1-9]\d{4})$/,
      ES: /^\d{5}$/,
      FI: /^\d{5}$/,
      FO: /^\d{3}$/,
      FR: /^(F-)?((2[A|B])|[\d]{2})[\d]{3}$/,
      GB: /^(GIR|[A-Z]\d[A-Z\d]??|[A-Z]{2}\d[A-Z\d]??)[ ]??(\d[A-Z]{1,2})$/,
      GI: /^GX11[ ]?1AA$/,
      GL: /^\d{4}$/,
      GR: /^\d{3}[ ]\d{2}$/,
      HU: /^\d{4}$/,
      IE: /^[A-Za-z\d]{3}[ ]?[A-Za-z\d]{4}$/,
      IS: /^\d{3}$/,
      IT: /^(V-|I-)?[\d]{5}$/,
      JE: /^JE\d[\dA-Za-z]?[ ]?\d[A-Za-z]{2}$/,
      LI: /^\d{4}$/,
      LT: /^\d{5}$/,
      LU: /^\d{4}$/,
      LV: /^LV-\d{4}$/,
      MC: /^980\d{2}$/,
      MT: /^[A-Za-z]{3}[ ]?\d{4}$/,
      NL: /^\d{4}[ ]?[A-Za-z]{2}$/,
      NO: /^\d{4}$/,
      PL: /^\d{2}(-\d{3})$/,
      PT: /^\d{4}-\d{3}$/,
      RO: /^\d{6}$/,
      SE: /^\d{3}[ ]?\d{2}$/,
      SI: /^\d{4}$/,
      SK: /^\d{3}([ ]?\d{2})$/,
      SM: /^4789\d$/,
      VA: /^00120$/,
    },
    taxRates: {
      DE: 0.19,
      AT: 0.20,
      CH: 0.077,
      NL: 0.21,
      GR: 0.24,
      LU: 0.17,
    },
    settings: settings,
    resellerDocListener: null,
    subuserDocListener: null,
    notificationsDocListener: null,
    temporary: {},
  },
  mutations: {
    storeTemporaryData(state, payload) {
      state.temporary = payload;
    },
    storeResellerData(state, payload) {
      state.resellerData = payload;
    },
    storeResellerSubUserData(state, payload) {
      state.resellerSubUserData = payload;
    },
    alertError(state, alertError) {
      state.alertError = alertError;
    },
    alertSuccess(state, alertSuccess) {
      state.alertSuccess = alertSuccess;
    },
    showNewsPanel(state, payload) {
      if (payload) {
        document.body.classList.add("news-panel-open")
      } else {
        document.body.classList.remove("news-panel-open")
      }
      state.showNewsPanel = payload;
    },
    storeNotifications(state, payload) {
      state.notifications = payload;
    },
    newVersion(state, payload) {
     state.newVersion = payload;
    },
    storeResellerDocListener(state, payload) { 
      state.resellerDocListener = payload;
    },
    storeSubuserDocListener(state, payload) { 
      state.subuserDocListener = payload;
    },
    storeNotificationsDocListener(state, payload) { 
      state.notificationsDocListener = payload;
    }
  },
  actions: {
    async alertSuccess({ commit }, newValue) {
      const translate = httpsCallable(functions, "translate");
      newValue.message = await translate({
        targetLanguageCode: i18n.locale,
        text: newValue.message,
      })
        .then((res) => {
          return res.data;
        })
        .catch((err) => {
          console.error(err);
          return newValue.message;
        });

      commit("alertSuccess", newValue);
    },
    async alertError({ commit }, newValue) {
      const translate = httpsCallable(functions, "translate");
      newValue.message = await translate({
        targetLanguageCode: i18n.locale,
        text: newValue.message,
      })
        .then((res) => {
          return res.data;
        })
        .catch((err) => {
          console.error(err);
          return newValue.message;
        });

      commit("alertError", newValue);
    },
    newVersion({ commit }, newValue) {
      commit("newVersion", newValue);
    }
  },
});
